import React, { Component } from 'react';
import translationService from './tools/Translator';


export class TranslatedComponent extends Component {
    componentDidMount() {
        translationService._changeLanguageHandler.push(this._handleReload);
    }

    componentWillUnmount() {
        if (translationService._changeLanguageHandler.indexOf(this._handleReload) !== -1)
            translationService._changeLanguageHandler.splice(translationService._changeLanguageHandler.indexOf(this._handleReload), 1);
    }

    _handleReload(event) { };
}


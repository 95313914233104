import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import translationService from './tools/Translator';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.renderLanguagesTable = this.renderLanguagesTable.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            languages: [],
            loading: true
        };
    }

    componentDidMount() {
        translationService.loadLanguages().then(() => { this.setState({ loading: false }) });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    renderLanguagesTable(languages) {
        const handleSelect = eventKey => {
            let value = eventKey;
            translationService.changeLanguage(value);
        };

        return (
            <DropdownButton id="dropdown-basic-button" variant="light" title="Langues">
                {languages.map(language =>
                    <Dropdown.Item onSelect={handleSelect} eventKey={language.id} key={language.id}><img src={language.flag} alt="{language.name}" />  {language.name}</Dropdown.Item>
                )}
            </DropdownButton>
        );
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">LifeLine.Doctor</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                                </NavItem>
                                <LoginMenu>
                                </LoginMenu>
                                {this.state.loading
                                    ? <DropdownButton id="dropdown-basic-button" variant="light" title="Chargement des langues..."></DropdownButton>
                                    : this.renderLanguagesTable(translationService._languages)}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import authService from './api-authorization/AuthorizeService';
import { timeToInt } from 'time-number';
import TimePicker from 'react-bootstrap-time-picker';
import DateTimePicker from 'react-datetime-picker'
import { TranslatedComponent } from './TranslatedComponent';
import translationService from './tools/Translator';

export class ConsultationForm extends TranslatedComponent {
    constructor(props) {
        super(props);

        this.filterState = this.filterState.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);

        var time = {
            format: 24,
            initialValue: "00:00",
            start: "00:00",
            end: "23:59",
            step: 10,
            onChange: this.handleTimeChange,
            value: 0
        };

        var translationKeys = [
            '$_consultation_note_placeholder',
            '$_consultation_title_placeholder',
            '$_consultation_note_legend',
            '$_consultation_description_placeholder',
            '$_consultation_description_legend',
            '$_consultation_submit_button'
        ];

        this.state = {
            patientId: this.props.patientId,
            loading: true,
            time: time,
            date: new Date(),
            title: "Consultation",
            translationKeys: translationKeys
        };
    }

    handleTimeChange(value) {
        const ret = this.state.time;
        ret.value = value;
        this.setState({ time: ret });
    }

    onChangeTitle = event => this.setState({ title: event.target.value })

    onChangeNotes = event => this.setState({ notes: event.target.value })

    onChangeDescription = event => this.setState({ description: event.target.value })

    onChangeDate = date => this.setState({ date })

    filterState() {
        const ret = { ...this.state.time };

        try {
            timeToInt(ret.start);
        } catch (ex) {
            ret.start = "00:00";
        }

        try {
            timeToInt(ret.end);
        } catch (ex) {
            ret.end = "23:59";
        }

        if (ret.step < 1) {
            ret.step = 30;
        }

        return ret;
    }

    componentDidMount() {
        super.componentDidMount();

        translationService.translate(this.state.translationKeys)
            .then(translations => { this.setState({ loading: false, translations }) });
    }

    _handleReload = (event) => {
        super._handleReload(event);
        this.setState({ loading: true });
        translationService.translate(this.state.translationKeys)
            .then(translations => { this.setState({ loading: false, translations }) });
    };

    render() {
        if (this.state.loading) {
            return ('Chargement en cours...');
        }

        var saveButtonDisableState = true;
        if ((!stringExtensions.isEmptyOrSpaces(this.state.description)
            || !stringExtensions.isEmptyOrSpaces(this.state.notes))
            && !stringExtensions.isEmptyOrSpaces(this.state.title)
            && this.state.date !== null
            && this.state.time !== null) {
            saveButtonDisableState = false;
        }

        return (
            <div className="patient-consultation-form-container">
                <div className="patient-consultation-form">
                    <Form.Group controlId="formTitle">
                        <Row className="patient-consultation-form-title-row">
                            <Col sm={2}>
                                <DateTimePicker
                                    value={this.state.date}
                                    format="dd/MM/yyyy"
                                    required={false}
                                    disableClock={true}
                                    calendarIcon={null}
                                    clearIcon={null}
                                    className="patient-consultation-form-title-row-input"
                                    onChange={this.onChangeDate}
                                />
                            </Col>
                            <Col sm={2}>
                                <TimePicker
                                    style={{ paddingLeft: '4px' }}
                                    {...this.filterState(this.state)} />
                            </Col>
                            <Col sm={8}>
                                <Form.Control
                                    name="title"
                                    placeholder={this.state.translations['$_consultation_title_placeholder']}
                                    className="patient-consultation-form-title-row-title"
                                    value={this.state.title}
                                    onChange={this.onChangeTitle} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="formNotes">
                        <Form.Control as="textarea" rows="4"
                            name="notes"
                            value={this.state.notes}
                            onChange={this.onChangeNotes}
                            placeholder={this.state.translations['$_consultation_note_placeholder']} />
                        <Form.Text className="text-muted">
                            {this.state.translations['$_consultation_note_legend']}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Control as="textarea" rows="4"
                            name="description"
                            value={this.state.description}
                            onChange={this.onChangeDescription}
                            placeholder={this.state.translations['$_consultation_description_placeholder']} />
                        <Form.Text className="text-muted">
                            {this.state.translations['$_consultation_description_legend']}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formSubmit">
                        <Row>
                            <Col sm={{ span: 4, offset: 8 }}>
                                <Button className="save-consultation" disabled={saveButtonDisableState} onClick={() => {
                                    var consultation = {
                                        date: new Date(this.state.date.getFullYear(), this.state.date.getMonth(), this.state.date.getDate()),
                                        time: this.state.time.value,
                                        title: this.state.title,
                                        description: this.state.description,
                                        notes: this.state.notes,
                                        patientId: this.state.patientId
                                    };

                                    authService.getAccessToken()
                                        .then(token => fetch('consultation', {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${token}`
                                            },
                                            body: JSON.stringify(consultation)
                                        }))
                                        .then(response => response.json())
                                        .then(result => {
                                            console.log(result);

                                            var time = this.state.time;
                                            time.value = 0;
                                            this.setState({
                                                time,
                                                date: new Date(),
                                                title: "Consultation",
                                                description: "",
                                                notes: ""
                                            });
                                            this.props.onConsultationCreated();
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }}>
                                    {this.state.translations['$_consultation_submit_button']}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </div>
        );
    }
}


export class stringExtensions extends Component {
    static isEmptyOrSpaces(str) {
        return str == undefined || str === null || str.trim() === '';
    }

    render() {
        return null;
    }
}
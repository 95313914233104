export class Translator {
    _languages = null;
    _selectedLanguage = '9AE0C97D-C5FF-4695-9656-E181FB6254A0';
    _translations = {};
    _changeLanguageHandler = [];

    async loadLanguages() {
        if (this._languages === null) {
            const response = await fetch('translation/getLanguages');
            const data = await response.json();
            this._languages = data;
        }
    }

    async translate(keys) {
        var translations = {};
        const pArray = keys.map(async key => {
            if (!(key in this._translations)) {
                const response = await fetch(`translation/Translate?key=${key}&language=${this._selectedLanguage}`);
                const data = await response.text();
                this._translations[key] = data;
            }

            translations[key] = this._translations[key];
        });

        await Promise.all(pArray);

        return translations;
    }

    changeLanguage(language) {
        if (this._selectedLanguage !== language) {
            this._selectedLanguage = language;
            this._translations = {};
            this._changeLanguageHandler.map(handler => {
                handler(new Event(""));
            });
        }
    }

    static get instance() { return translationService }
}

const translationService = new Translator();

export default translationService;

import React, { Component } from 'react';
import { TimelineItem } from './TimelineItem';
import './Timeline.css';

export class Timeline extends Component {
    render() {
        //var rows = [];
        //for (var i = 0; i < 10; i++) {
        //    var isExam = i % 2 === 0;
        //    var item = <TimelineItem key={i} isExam={isExam} />;
        //    this.state.timelineItems.push(item);
        //    rows.push(item);
        //}

        var rows = [];
        if (this.props.consultations !== undefined && this.props.consultations !== [])
            rows = this.props.consultations.map((consult, index) => <TimelineItem key={consult.id} consultation={consult} index={index} />);

        return (
            <div className="container-fluid patient-timeline">
                <div className="row">
                    <div id="timeline">
                        <div className="row timeline-movement timeline-movement-top">
                            <div className="timeline-badge timeline-future-movement">
                                <p>2018</p>
                            </div>
                        </div>
                        {rows}
                    </div>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Timeline } from './timeline/Timeline';
import { ConsultationForm } from './ConsultationForm';

export class Patient extends Component {
    constructor(props) {
        super(props);

        var pieces = this.props.location.pathname.split('/');
        var patientId = pieces[pieces.length - 1];
        this.state = { patientId, loading: true, consultations: [] };
    }

    componentDidMount() {
        authService.getAccessToken()
            .then(token => fetch('patient?id=' + this.state.patientId, {
                headers: { 'Authorization': `Bearer ${token}` }
            }))
            .then(response => response.json())
            .then(result => {
                if (result !== null && result !== undefined) {
                    this.getConsultations()
                        .then(result => this.setState({ consultations: result, loading: false }));
                } else {
                    this.props.history.push({ pathname: '/' });
                }
            })
            .catch(error => {
                console.log(error);
                this.props.history.push({ pathname: '/' });
            });
    }

    async getConsultations() {
        var token = await authService.getAccessToken();
        var response = await fetch('consultation/list?id=' + this.state.patientId, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        var result = await response.json();
        return result;
    }

    renderPatientData() {

        return (<React.Fragment>
            <ConsultationForm patientId={this.state.patientId} onConsultationCreated={() =>
                this.getConsultations()
                    .then(result => this.setState({ consultations: result }))} />
            <Timeline consultations={this.state.consultations} />
        </React.Fragment>);
    }

    render() {
        let content = this.state.loading
            ? <p><em>Loading...{this.state.patientId}</em></p>
            : this.renderPatientData();

        return content;
    }
}

import React, { Component } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import translationService from './tools/Translator';
import { TranslatedComponent } from './TranslatedComponent';
import authService from './api-authorization/AuthorizeService'

export class Home extends TranslatedComponent {
    static displayName = Home.name;
    constructor() {
        super();

        this.state = {
            loading: true,
            createPatientModalShow: false,
            value: '',
            suggestions: [],
            newPatient: []
        };

        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.createPatientFormSubmit = this.createPatientFormSubmit.bind(this);
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    async getSuggestions(value) {
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');

        const token = await authService.getAccessToken();
        const response = await fetch('patient/search?filter=' + value, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        const patients = await response.json();

        return patients;
    }

    onSuggestionsFetchRequested = async ({ value }) => {
        this.setState({
            suggestions: await this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestionValue(suggestion) {
        return suggestion.firstName + ' ' + suggestion.lastName;
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.firstName + ' ' + suggestion.lastName}</span>
        );
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.history.push('/patient/' + suggestion.id);
    }

    componentDidMount() {
        super.componentDidMount();
        translationService.translate(['$_home_searchbar_placeholder'])
            .then(translations => { this.setState({ loading: false, translations }) });
    }

    _handleReload = (event) => {
        super._handleReload(event);
        this.setState({ loading: true });
        translationService.translate(['$_home_searchbar_placeholder'])
            .then(translations => { this.setState({ loading: false, translations }) });
    };

    onChangeCreatePatientForm = event => {
        let fieldName = event.target.name;
        let fleldVal = event.target.value;
        this.setState({ newPatient: { ...this.state.newPatient, [fieldName]: fleldVal } })
    }

    createPatientFormSubmit = async (e) => {
        e.preventDefault();
        var newPatient = this.state.newPatient;

        // ...then we update the users state
        authService.getAccessToken()
            .then(token => fetch('patient', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newPatient)
            }))
            .then(response => response.json())
            .then(patientId => {
                console.log(patientId);
                this.props.history.push('/patient/' + patientId);
            });
    }

    render() {
        if (this.state.loading) {
            return ('Chargement en cours...');
        }

        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: this.state.translations['$_home_searchbar_placeholder'],
            value,
            onChange: this.onChange
        };

        return (
            <>
                <Row className="home-search-row">
                    <Col sm={8} className="home-search-col">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            onSuggestionSelected={this.onSuggestionSelected}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                            className="form-control" />
                    </Col>
                    <Col sm={2} className="home-search-col">
                        <Button variant="info" onClick={() => alert("hello")}>Search</Button>
                    </Col>
                    <Col sm={2} className="home-search-col">
                        <Button variant="danger" onClick={() => this.setState({ createPatientModalShow: true })}>Create</Button>
                    </Col>
                </Row>

                <Modal
                    show={this.state.createPatientModalShow}
                    onHide={() => this.setState({ createPatientModalShow: false })}
                    centered>
                    <Modal.Body>
                        <Form onSubmit={this.createPatientFormSubmit}>
                            <Form.Group controlId="newPatientFirstName">
                                <Form.Row>
                                    <Form.Control
                                        name="firstName"
                                        placeholder="Firstname"
                                        //placeholder={this.state.translations['$_consultation_title_placeholder']}
                                        value={this.state.newPatient.firstName}
                                        onChange={this.onChangeCreatePatientForm}
                                    />
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="newPatientLastName">
                                <Form.Row>
                                    <Form.Control
                                        name="lastName"
                                        placeholder="Lastname"
                                        //placeholder={this.state.translations['$_consultation_title_placeholder']}
                                        value={this.state.newPatient.lastName}
                                        onChange={this.onChangeCreatePatientForm}
                                    />
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="newPatientPhoneNumber">
                                <Form.Row>
                                    <Form.Control
                                        name="phoneNumber"
                                        placeholder="phoneNumber"
                                        //placeholder={this.state.translations['$_consultation_title_placeholder']}
                                        value={this.state.newPatient.phoneNumber}
                                        onChange={this.onChangeCreatePatientForm}
                                    />
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="newPatientEmail">
                                <Form.Row>
                                    <Form.Control
                                        name="email"
                                        placeholder="email"
                                        //placeholder={this.state.translations['$_consultation_title_placeholder']}
                                        value={this.state.newPatient.email}
                                        onChange={this.onChangeCreatePatientForm}
                                    />
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="newPatientBirthday">
                                <Form.Row>
                                    <Form.Control
                                        name="birthday"
                                        placeholder="Birthday"
                                        //placeholder={this.state.translations['$_consultation_title_placeholder']}
                                        value={this.state.newPatient.birthday}
                                        onChange={this.onChangeCreatePatientForm}
                                    />
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="formSubmit">
                                <Form.Row>
                                    <Button type="submit">
                                        {this.state.translations['$_consultation_submit_button']}
                                    </Button>
                                </Form.Row>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

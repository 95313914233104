import React, { Component } from 'react'

export class TimelineItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: false,
            consultation: props.consultation,
            index: props.index
        }

        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover() {
        this.setState({ hover: !this.state.hover })
    }

    render() {
        var circleStyle;
        var circleClass;
        var colPositionClass;
        var itemPositionClass;
        var panelClass;
        if (this.state.hover) {
            circleStyle = { backgroundColor: '#4997cd' }
        } else {
            circleStyle = { backgroundColor: '#fff' }
        }

        if (this.state.index % 2 === 1) {
            circleClass = "debits center-right";
            panelClass = "debits fadeInRight";
            itemPositionClass = "offset-sm-6 col-sm-6";
            colPositionClass = "offset-sm-1 col-sm-11";
        } else {
            circleClass = "credits center-left";
            panelClass = "credits fadeInLeft";
            itemPositionClass = "col-sm-6";
            colPositionClass = "col-sm-11";
        }

        return (
            <div className="row timeline-movement">
                <div className={'timeline-badge ' + circleClass} style={circleStyle}>

                </div>
                <div className={itemPositionClass + ' timeline-item'}>
                    <div className="row">
                        <div className={colPositionClass}>

                            <div className={'timeline-panel anim animate animated ' + panelClass}
                                onMouseEnter={this.toggleHover}
                                onMouseLeave={this.toggleHover}
                                ref={(divElement) => this.divElement = divElement}>
                                <ul className="timeline-panel-ul">
                                    <div className="righting-wrap">
                                        <li><a href="#" className="importo"><p className="causale">{this.state.consultation.title}</p></a></li>
                                        <li><p className="causale">{this.state.consultation.doctorName}</p> </li>
                                        <li><p className="causale">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> </li>
                                        <li><p><small className="text-muted"><i className="glyphicon glyphicon-time"></i> {this.state.consultation.date}</small></p> </li>
                                    </div>
                                    <div className="clear"></div>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
